'use client';

import * as Sentry from '@sentry/nextjs';
import NextError from 'next/error';
import { useEffect } from 'react';

/**
 * This component is used to handle global errors in the Next.js application.
 * It captures errors using Sentry and renders a fallback error page using NextError.
 */
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  // Capture the error in Sentry for monitoring
  useEffect(() => {
    if (error) {
      console.error('Captured error in GlobalError:', error); // Log error to the console for debugging
      Sentry.captureException(error); // Send the error details to Sentry
    }
  }, [error]);
  return <html lang="en" data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        {/* Render the default Next.js error page */}
        <NextError statusCode={500} // Set a generic 500 status code for server-side errors
      title="An unexpected error occurred. Please try again later." data-sentry-element="NextError" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}